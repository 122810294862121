<template>
    <navbar>
        <div class="nav-item">
            <button
                v-if="selected.length"
                type="button"
                class="btn btn-danger btn-sm me-2"
                @click="removeSelected"
            >
                Delete Selected
            </button>
            <button
                type="button"
                class="btn btn-primary btn-sm"
                data-bs-toggle="modal"
                data-bs-target="#addEntryModal"
            >
                Add Entry
            </button>
        </div>
    </navbar>
    <div class="home">
        <div class="container">
            <ag-grid-vue
                class="ag-theme-material"
                :columnDefs="columnDefs"
                row-selection="multiple"
                @row-selected="onRowSelected"
                rowModelType="serverSide"
                serverSideStoreType="partial"
                :paginationPageSize="pagination.pageSize"
                :cacheBlockSize="pagination.cacheSize"
                :pagination="true"
                @grid-ready="onGridReady"
                @cell-value-changed="onCellValueChanged"
                :animateRows="true"
            >
            </ag-grid-vue>
        </div>
    </div>
    <add />
</template>

<script>
import { AgGridVue } from "ag-grid-vue3";
import Navbar from "@/components/Navbar";
import Add from "@/components/Entries/Add";
import axios from "axios";
import ManageActions from "@/components/Entries/ManageActions";

export default {
    name: "Manage",
    // eslint-disable-next-line vue/no-unused-components
    components: { Add, Navbar, AgGridVue, ManageActions },
    data() {
        return {
            pagination: {
                pageSize: 100,
                cacheSize: 100,
            },
            gridApi: null,
            columnApi: null,
            columnDefs: [
                {
                    field: "email",
                    headerName: "Email",
                    sortable: true,
                    filter: true,
                    checkboxSelection: true,
                    editable: true,
                    flex: 1,
                },
                {
                    field: "createdAt",
                    headerName: "Created At",
                    sortable: true,
                    filter: true,
                    cellRenderer: (params) => {
                        return new Date(params.value).toLocaleDateString(
                            undefined,
                            {
                                month: "long",
                                day: "2-digit",
                                year: "numeric",
                            }
                        );
                    },
                },
                {
                    field: "id",
                    headerName: "Actions",
                    sortable: false,
                    filter: false,
                    cellRendererFramework: ManageActions,
                },
            ],
            entries: [],
            selected: [],
        };
    },
    methods: {
        onRowSelected(e) {
            const id = e.node.data.id;

            if (this.selected.includes(id)) {
                this.selected = this.selected.filter((_id) => _id !== id);
                return;
            }
            this.selected.push(id);
        },
        onGridReady(params) {
            this.gridApi = params.api;
            this.gridColumnApi = params.columnApi;

            params.api.setServerSideDatasource({
                getRows: (params) => {
                    axios
                        .post("/entries/get", params.request, {
                            headers: {
                                "Content-Type":
                                    "application/json; charset=utf-8",
                            },
                        })
                        .then(({ data }) => {
                            params.success({
                                rowData: data.rows,
                                rowCount: data.lastRow,
                            });
                        })
                        .catch((error) => {
                            console.error(error);
                            params.fail();
                        });
                },
            });
        },
        onCellValueChanged(params) {
            const data = params.data;

            axios.put(`/entries/${data.id}`, data).catch((e) => {
                if (e.response?.data?.message) {
                    return alert(e.response?.data?.message);
                }
                alert("There was some error while updating your last colum");
            });
        },
        removeSelected() {
            if (confirm("Are you sure want to delete?")) {
                const ids = this.selected.join();
                axios
                    .delete(`/entries/${ids}`)
                    .then(() => {
                        window.location.reload();
                        // this.$emit("removed", this.id);
                    })
                    .catch(() => {
                        alert(
                            "There was some error while deleting selected email(s). Please try again Later"
                        );
                    });
            }
        },
    },
    created() {
        /* axios.get("/entries").then(({ data }) => {
            this.entries = data.map((entry) => ({
                ...entry,
                createdAt: new Date(entry.createdAt).toLocaleDateString(
                    undefined,
                    {
                        month: "long",
                        day: "2-digit",
                        year: "numeric",
                    }
                ),
            }));
        }); */
    },
};
</script>

<style lang="scss">
.ag-theme-material {
    height: calc(100vh - 56px);
}
</style>
