<template>
    <div class="d-inline-flex align-items-center justify-content-center">
        <button class="btn btn-sm btn-light text-danger" @click="remove">
            Delete
        </button>
    </div>
</template>

<script>
import axios from "axios";

export default {
    name: "ManageActions",
    props: ["params"],
    computed: {
        id() {
            return this.params.data?.id;
        },
    },
    methods: {
        remove() {
            if (confirm("Are you sure want to delete?")) {
                axios
                    .delete(`/entries/${this.id}`)
                    .then(() => {
                        window.location.reload();
                        // this.$emit("removed", this.id);
                    })
                    .catch(() => {
                        alert(
                            "There was some error while deleting selected email(s). Please try again Later"
                        );
                    });
            }
        },
    },
};
</script>

<style scoped></style>
