<template>
    <router-view v-if="authorized" />
</template>

<script>
import axios from "axios";
import {
    authorizeRequest,
    getToken,
    removeToken,
    setToken,
} from "@/helpers/auth";

export default {
    name: "App",
    data() {
        return {
            authorized: false,
        };
    },
    methods: {
        login() {
            const token = prompt("Please enter authorization token");

            if (!token) {
                this.login();
            }

            setToken(token);
            authorizeRequest(token);

            axios
                .post("/login")
                .then(() => {
                    window.location.reload();
                })
                .catch(() => {
                    removeToken();
                    alert("Please enter Valid Token");
                    this.login();
                });
        },
    },
    created() {
        const token = getToken();

        if (!token) {
            return this.login();
        }

        authorizeRequest(token);

        axios
            .post("/login")
            .then(() => {
                this.authorized = true;
            })
            .catch(() => {
                removeToken();
                alert("You need to Login Again!");
                this.login();
            });
    },
};
</script>

<style lang="scss">
@import "./assets/scss/app";
</style>
