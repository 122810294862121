<template>
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
        <div class="container">
            <a class="navbar-brand fw-bolder" href="#">Customer Verify</a>
            <button
                class="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
            >
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                    <li class="nav-item">
                        <a class="nav-link active" aria-current="page" href="#">
                            Manage Entries
                        </a>
                    </li>
                </ul>
                <ul class="nav ms-auto mb-2 mb-lg-0 align-items-center">
                    <slot></slot>
                    <li class="nav-item">
                        <a
                            class="nav-link text-dark"
                            aria-current="page"
                            href="#"
                            @click="logout"
                        >
                            Logout
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>

<script>
import { removeToken } from "@/helpers/auth";

export default {
    name: "Navbar",
    methods: {
        logout() {
            removeToken();
            window.location.reload();
        },
    },
};
</script>

<style lang="scss" scoped>
.navbar {
    height: 56px;
}
</style>
