<template>
    <div
        class="modal fade"
        id="addEntryModal"
        tabindex="-1"
        aria-labelledby="addEntryModalLabel"
        aria-hidden="true"
    >
        <div class="modal-dialog">
            <form @submit.prevent="submit">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="addEntryModalLabel">
                            Add new Entries
                        </h5>
                        <button
                            type="button"
                            class="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div class="modal-body">
                        <div class="form-group">
                            <label
                                for="exampleFormControlTextarea1"
                                class="form-label"
                            >
                                Insert Entries (Seperated By Comma)
                            </label>
                            <textarea
                                class="form-control"
                                id="exampleFormControlTextarea1"
                                rows="8"
                                v-model="entries"
                            ></textarea>
                        </div>
                        <div class="text-center my-4">
                            <span class="text-uppercase">OR</span>
                        </div>
                        <div class="mb-3">
                            <label for="formFile" class="form-label">
                                Choose a CSV to upload entries
                            </label>
                            <input
                                class="form-control"
                                type="file"
                                id="csv-file"
                                accept=".csv"
                                @change="onFileChange"
                            />
                        </div>
                        <div class="alert alert-warning" role="alert">
                            You can only insert entries manually or with CSV at
                            a once. If you provide file, Manual entries will be
                            ignored.
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button
                            type="reset"
                            class="btn btn-secondary"
                            data-bs-dismiss="modal"
                        >
                            Close
                        </button>
                        <button
                            type="submit"
                            class="btn btn-primary"
                            :disabled="loading"
                        >
                            Add Entries
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    name: "Add",
    data() {
        return {
            entries: null,
            csv: null,
            loading: false,
        };
    },
    methods: {
        submit() {
            if (!this.csv && !this.entries) {
                return alert("Please fill any one method!");
            }

            this.loading = true;

            if (this.csv) {
                return this.importFromCsv();
            }

            this.bulkInsert();
        },
        importFromCsv() {
            const data = new FormData();
            data.append("csv", this.csv);

            axios
                .post("/entries/import", data)
                .then(() => {
                    window.location.reload();
                })
                .catch((error) => {
                    this.loading = false;
                    alert(error.message);
                });
        },
        bulkInsert() {
            const emails = this.entries.split(",").map((email) => email.trim());

            axios
                .post("/entries", { data: emails })
                .then(() => {
                    window.location.reload();
                })
                .catch((error) => {
                    this.loading = false;
                    alert(error.message);
                });
        },
        onFileChange(e) {
            this.csv = e.target.files[0];
        },
    },
};
</script>

<style scoped></style>
