import axios from "axios";

export const setToken = (token) => {
    localStorage.setItem("token", token);
};

export const getToken = () => {
    return localStorage.getItem("token");
};

export const removeToken = () => {
    localStorage.removeItem("token");
};

export const authorizeRequest = (token) => {
    axios.defaults.headers["Authorization"] = token;
};
