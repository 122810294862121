import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "bootstrap/js/dist/modal";
import axios from "axios";
import "ag-grid-enterprise";

axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT;

createApp(App).use(store).use(router).mount("#app");
